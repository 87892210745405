import ShareCenterControlPage from "../../../common/Share/ShareCenterControlPage";
import { DashboardTravelRowInfo } from "../components/DashboardTravels";

export default function Travels(params) {
  return (
    <ShareCenterControlPage>
      <DashboardTravelRowInfo />
      <TravelsTable >
            Tarjetas - For elements of Tarjetas // TravelsCard.
      </TravelsTable>
      <DashboardTravelRowInfo />
      <TravelsTable >
            Tarjetas - For elements of Tarjetas // TravelsCard.
      </TravelsTable>
    </ShareCenterControlPage>
  );
}

export function TravelsTable(params) {
  return (
    <div
      className="mt-3"
      style={{
        gap: "14px",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(392px, auto))",
      }}
    >
      {params?.children}
    </div>
  );
}
