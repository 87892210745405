import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShareRadio } from "../../../common/Share/ShareRadio";
import ShareModalIndex from "../../../common/Share/ShareModalIndex";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import EcommerceFormHelpMessage from "../components/EcommerceFormHelpMessage";
import EcommerceFormCustomHolder from "../components/EcommerceFormCustomHolder";
import EcommerceFormSocialHolder from "../components/EcommerceFormSocialHolder";
import EcommerceFormProfileHolder from "../components/EcommerceFormProfileHolder";
import { updateVirtualEcommerce } from "../../Framework/redux/EcommerceSideEffect";
import EcommerceFormModalSelect from "../components/modals/EcommerceFormModalSelect";
import ShareModalWarningMessage from "../../../common/Share/ShareModalWarningMessage";
import EcommerceRegisterModalInfo from "../components/modals/EcommerceRegisterModalInfo";
import EcommerceFormModalAssociate from "../components/modals/EcommerceFormModalAssociate";
import EcommerceFormModalProfilePicture from "../components/modals/EcommerceFormModalProfilePicture";
import { fetchVirtualEcommerce } from "../../Framework/redux/EcommerceSideEffect";
import { FormattedMessage } from "react-intl";
import { ModalClipboardContext } from "../../../common/routes/routes";
import { fetchUserProfile } from "../../../Login/Framework/redux/loginSideEffects";

export default function EcommerceForm() {
  const ecommerceData = useSelector((state) => state.ecommerceData);
  const dispatch = useDispatch();
  const { setModalTexts } = useContext(ModalClipboardContext);
  const [formData, setFormData] = useState({
    errorToggler: false,
    body: {
      topics: { list: [], selected: "", error: "" },
      color: { selected: 2, error: "" },
      privacy: { selected: "a", error: "" },
      history: { value: "", error: "" },
      phrase: { value: "", error: "" },
      id: { value: "", error: "" },
      photo: { value: "", error: "" },
      sitename: { value: "", error: "" },
      accountMP: { value: "", error: "" },
    },
  });
  const [errorArray, setErrorArray] = useState([]);
  const [onSending, setOnSending] = useState(false);

  const onChangeFormBodyByPath = (path, value) => {
    setFormData((prevState) => {
      const newBody = { ...prevState.body };
      const updateNestedField = (obj, keys, value) => {
        const [key, ...rest] = keys;
        try {
          if (rest.length === 0) {
            obj[key] = value;
          } else {
            if (!obj[key]) obj[key] = {};
            updateNestedField(obj[key], rest, value);
          }
        } catch (error) {
          console.log(error);
        }
      };
      updateNestedField(newBody, path.split("."), value);
      return {
        ...prevState,
        body: newBody,
        errorToggler: false,
      };
    });
  };

  const onClickActivator = (activator) => {
    const clickMe = document.getElementById(activator);
    clickMe.click();
    localStorage.setItem("new-url-photo", "true");
  };

  const validatePerRequest = (value, request) => {
    const ar = [
      "just",
      "JUST",
      "oficial",
      "OFICIAL",
      "Just",
      "Oficial",
      "SWISS",
      "COLOMBIA",
      "colombia",
      "Colombia",
      "BIENESTAR",
      "YOGA",
      "ACEITES",
      "CONSULTORA",
      "NATURALEZA",
      "AROMATERAPIA",
    ];
    if (request == "notVoid") {
      return value?.length > 0;
    }
    if (request?.type == "includes") {
      return value.includes(request?.value);
    }
    if (request?.type == "validateWords") {
      for (const palabra of ar) {
        const booleanTest = value.includes(palabra);
        if (booleanTest) {
          return false;
        }
        return true;
      }
    }
    if (request?.type == "failedWord") {
      for (const palabra of ar) {
        const booleanTest = value.includes(palabra);
        if (booleanTest) {
          return palabra;
        }
        return true;
      }
    }
  };

  const onSubmit = async (forcedVisible) => {
    setOnSending(true);
    const callBackNetworkConditionals = (value, label) => {
      if (!value) {
        return true;
      }
      const country = localStorage.getItem("country");
      const socialNetworkConditionals = {
        ar:
          validatePerRequest(value, {
            type: "includes",
            value: `/www.${label}.com/`,
          }) &&
          validatePerRequest(value, {
            type: "validateWords",
          }),
        mx: true,
        co: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        cl: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        cr: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        pa: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        uy: validatePerRequest(value, {
          type: "includes",
          value: `/www.${label}.com/`,
        }),
        pe: validatePerRequest(value, {
          type: "includes",
          value: label == "facebook" ? "facebook" : `${label}.com`,
        }),
      };

      return socialNetworkConditionals[country];
    };
    const replaceEmptyStringsWithNull = (obj) => {
      if (!obj || typeof obj !== "object") return obj;
    
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, value === "" ? null : value])
      );
    }
    let errorHandler = {
      facebook: !callBackNetworkConditionals(
        formData?.body?.socialNetwork?.facebook,
        "facebook"
      ),
      instagram: !callBackNetworkConditionals(
        formData?.body?.socialNetwork?.instagram,
        "instagram"
      ),
      linkedin: !callBackNetworkConditionals(
        formData?.body?.socialNetwork?.linkedin ?? formData?.body?.socialNetwork?.linkedIn,
        "linkedin"
      ),
      youtube: !callBackNetworkConditionals(
        formData?.body?.socialNetwork?.youtube,
        "youtube"
      ),
    };
    let isErrorToggler = false;
    for (const [key, value] of Object.entries(errorHandler)) {
      if (value) {
        isErrorToggler = true;
        setFormData((prevState) => {
          let adjustingState = { ...prevState };
          adjustingState.errorToggler = true;
          let newBody = { ...prevState.body };
          return {
            ...adjustingState,
            body: newBody,
          };
        });
      }
    }
    if (isErrorToggler) {
      setErrorArray(errorHandler);
      setOnSending(false);
      return;
    }
    const preBody = {
      id: ecommerceData?.ecommerceData.data?.id,
      clientId: localStorage.getItem("clientId"),
      storeName: formData?.body?.sitename?.value,
      phrase: formData?.body?.phrase?.value?.title ?? formData?.body?.phrase?.value,
      history: formData?.body?.history?.value,
      socialNetwork: replaceEmptyStringsWithNull(formData?.body?.socialNetwork),
      colorBackground: formData?.body?.color?.selected,
      visible: forcedVisible ? true : formData?.body?.privacy?.selected,
    };
    const result = await dispatch(updateVirtualEcommerce(preBody));
    if (result?.error?.message) {
      return;
    }
    await dispatch(fetchUserProfile())
    refetchDataFromServer();
    const modalData = {
      title: "Cambios de Tienda Virtual en validación",
      text: "Los cambios se han guardado y están pendientes de aprobación",
      status: "info",
      show: false,
      bottom: 84,
    };
    setModalTexts(modalData);
    setOnSending(false);
  };

  const prevOnSubmitIfNotVisible = () => {
    const clickMe = document.getElementById(
      "ecommerce-no-visible-pop-up-index"
    );
    clickMe.click();
  };

  const refetchDataFromServer = async () => {
    await dispatch(fetchVirtualEcommerce());
  };

  const onVoid = () => {};

  useEffect(() => {
    setFormData({
      ...formData,
      body: {
        ...formData.body,
        topics: {
          ...formData.body.topics,
          list: ecommerceData.ecommerceDataTopics.data?.topics,
        },
        history: {
          ...formData.body.history,
          value: ecommerceData.ecommerceData.data?.history,
        },
        color: {
          ...formData.body.color,
          selected: ecommerceData.ecommerceData.data?.colorBackground ?? 2,
        },
        id: {
          ...formData.body.id,
          value: ecommerceData.ecommerceData.data?.id,
        },
        phrase: {
          ...formData.body.phrase,
          value: ecommerceData.ecommerceData.data?.phrase,
        },
        photo: {
          ...formData.body.photo,
          value: ecommerceData.ecommerceData.data?.photo,
        },
        sitename: {
          ...formData.body.sitename,
          value: ecommerceData.ecommerceData.data?.storeName,
        },
        accountMP: {
          ...formData.body.accountMP,
          value: ecommerceData.ecommerceData.data?.accountMP,
        },
        privacy: { selected: ecommerceData.ecommerceData.data?.visible, error: "" },
        socialNetwork: { ...ecommerceData?.ecommerceData?.data?.socialNetwork },
      },
    });
  }, [ecommerceData?.ecommerceData?.data, ecommerceData.ecommerceDataTopics?.data]);

  return (
    <> 
    <ShareModalIndex id="ecommerce-no-visible-pop-up" />
    <div
        data-bs-toggle="modal"
        data-bs-target="#ecommerce-profile-helper"
        id="ecommerce-profile-helper-activator"
      ></div>
    <div
      style={{ gap: "40px", marginBottom: "72px" }}
      className="d-flex flex-column mt-4 pt-3"
    >
      <ShareModalStructure id={"form-select-phrase"} maxWidth={"496px"}>
        <EcommerceFormModalSelect
          onSelect={onChangeFormBodyByPath}
          label={"phrase.value"}
          list={formData.body.topics.list}
        />
      </ShareModalStructure>
      <ShareModalStructure id={"form-associate"} maxWidth={"496px"}>
        <EcommerceFormModalAssociate />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"496px"} id={"register-modal-info"}>
        <EcommerceRegisterModalInfo />
      </ShareModalStructure>
      <ShareModalStructure id={"ecommerce-profile-helper"} maxWidth={"496px"}>
        <EcommerceFormModalProfilePicture />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"496px"} id={"ecommerce-profile-pop-up"}>
        <ShareModalWarningMessage
          buttonConfirmedMessage={<FormattedMessage id="buttons.confirm" /> }
          description={ <FormattedMessage id="ecommerce.form.modal.warning.one.p" />}
          title={<FormattedMessage id="ecommerce.form.modal.warning.one.title" />}
          onButtonConfirmedClick={() =>
            onClickActivator("ecommerce-profile-helper-activator")
          }
          buttonIsCanceled={true}
        />
      </ShareModalStructure>
      <ShareModalStructure
        maxWidth={"496px"}
        id={"ecommerce-no-visible-pop-up"}
      >
        <ShareModalWarningMessage
          buttonConfirmedMessage={<FormattedMessage id="buttons.confirm" />}
          description={
            <FormattedMessage id="ecommerce.form.modal.no.visible.description" />
          }
          title={<FormattedMessage id="ecommerce.form.modal.no.visible.title" />}
          onButtonConfirmedClick={() => onSubmit()}
          buttonIsCanceled={true}
        />
      </ShareModalStructure>
      
      <EcommerceFormHelpMessage
        ecommerceData={ecommerceData?.ecommerceData?.data}
        onSubmit={() => onSubmit(true)}
      />
      <EcommerceFormProfileHolder
        ecommerceData={ecommerceData}
        formData={formData}
      />
      <EcommerceFormCustomHolder
        onChange={onChangeFormBodyByPath}
        ecommerceData={ecommerceData?.ecommerceData}
        formData={formData}
        errorArray={errorArray}
      />
      <EcommerceFormSocialHolder
        onChange={onChangeFormBodyByPath}
        formData={formData}
        errorArray={errorArray}
        validatePerRequest={validatePerRequest}
      />
      <EcommerceFormRadioHolder
        dataLabel={"color"}
        formData={formData}
        onSelect={onChangeFormBodyByPath}
        label={"Paleta"}
        sublabel={"Color de fondo"}
        index={0}
      />
      <EcommerceFormRadioHolder
        dataLabel={"privacy"}
        formData={formData}
        onSelect={onChangeFormBodyByPath}
        label={"Privacidad"}
        sublabel={"Visibilidad de tu Tienda"}
        index={1}
      />
      <button
        onClick={
          onSending ? onVoid : formData?.body?.privacy?.selected
            ? onSubmit
            : prevOnSubmitIfNotVisible
        }
        id="on-submit-confirm"
        className=" position-relative btnLoginWidth rounded-3 border-0 skyBg text-white museo-sans-600 s14 "
      >
        <FormattedMessage id="save" />
      </button>
    </div>
    </>
  );
}

export function EcommerceFormRadioHolder(params) {
  const arrayRadios = [
    {
      labelId: "Rosa",
      disabled: false,
      active: params?.formData?.body[params?.dataLabel]?.selected == 2,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, 2),
    },
    {
      labelId: "Azul",
      disabled: false,
      active: params?.formData?.body[params?.dataLabel]?.selected == 1,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, 1),
    },
    {
      labelId: "Gris",
      disabled: false,
      active: params?.formData?.body[params?.dataLabel]?.selected == 3,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, 3),
    },
  ];
  const arrayRadiosTwo = [
    {
      labelId: "Visible",
      disabled: false,
      active: params?.formData?.body[params?.dataLabel]?.selected === true ,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, true),
    },
    {
      labelId: "No Visible",
      disabled: false,
      active: params?.formData?.body[params?.dataLabel]?.selected === false,
      onClick: () => params?.onSelect(`${params?.dataLabel}.selected`, false),
    },
  ];
  const wholeArrays = [arrayRadios, arrayRadiosTwo];

  return (
    <div className="d-flex flex-column gap-3">
      <div>
        <p className=" s20 museo-sans-400">{params?.label}</p>
      </div>
      <div>
        <p className=" s16 museo-sans-600">{params?.sublabel}</p>
      </div>
      <div className="d-flex gap-4 museo-sans-400 s14">
        <ShareRadio gap={"8px"} arrayRadios={wholeArrays[params?.index]} />
      </div>
    </div>
  );
}
