export default function ShareModalIndex(params) {
  return (
    <div
      id={`${params?.id}-index`}
      className="pointer"
      data-bs-toggle="modal"
      data-bs-target={`#${params?.id}`}
    >
      {params?.children}
    </div>
  );
}

