import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchTravelData = createAsyncThunk(
  "fetchTravelData",
    async (none, { rejectWithValue }) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            return await customNetServAxios().get(`${country}/travels/${clientId}/overview`);
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export {
    fetchTravelData
};
