import { ReactComponent as Oferts } from "../../../svgs/ic-offer-web.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

import {
  faUser,
  faCalendarDays,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import EcommerceStatus from "../../../../Ecommerce/Presentation/components/EcommerceStatus";

export default function NavBarSecondRow({
  activeBackground,
  onHistory,
  location,
  showDropdownAccount,
  dropdownAccount,
  profileData,
  hasImage
}) {
  const directorId = localStorage.getItem("directorId");

  const onEcommerceClick = () => {
    if (hasImage) {
      onHistory("/dashboard/ecommerce");
      return;
    }
    const clickMe = document.getElementById("ecommerce-profile-picture-first-step-activator");
    clickMe.click();
  };

  return (
    <ul className="nav--btn-holder w-100">
      <div
        style={
          location?.pathname?.indexOf("/dashboard/weeklydeals") != -1
            ? { background: activeBackground }
            : {}
        }
        onClick={() => onHistory("/dashboard/weeklydeals")}
        className="nav-info"
      >
        <i style={{ top: -3 }} className=" position-relative s16">
          <Oferts />
        </i>
        <div className="nav-desc museo-sans-500 ">
          <FormattedMessage
            id="nav.label.unknown.index"
            defaultMessage="Great Practice"
          />
        </div>
      </div>
      <div
        onClick={showDropdownAccount}
        style={
          location?.pathname?.indexOf("/dashboard/account") != -1
            ? { background: activeBackground }
            : {}
        }
        className="nav-info "
      >
        <div className=" position-absolute w-75 h-100"></div>
        <i className="s14">
          <FontAwesomeIcon icon={faUser} />
        </i>
        <div className="nav-desc museo-sans-500 ">
          <p>
            <FormattedMessage
              id="nav.label.five.index"
              defaultMessage="My Profile"
            />
          </p>
        </div>
        <i className="bi bi-chevron-down nav-desc museo-sans-500  ms-auto"></i>
      </div>
      {dropdownAccount ? (
        <>
          <div
            onClick={() => onHistory("/dashboard/account")}
            className="nav-info navbar--dropdown-menu"
          >
            <aside></aside>
            <p className="nav-item-desc">
              <FormattedMessage id="navbar.profile.landing" />
            </p>
          </div>
          <div
              onClick={() => onEcommerceClick("/dashboard/ecommerce/new")}
              className="nav-info navbar--dropdown-menu"
            >
              <aside></aside>
              <p className="d-flex gap-2 nav-item-desc">
                Tu Tienda Virtual
                <div
                  style={{ top: "-2px" }}
                  className=" s8 position-relative"
                >
                  <EcommerceStatus />
                </div>
              </p>
            </div>
          <div
            onClick={() => onHistory("/dashboard/account/status")}
            className="nav-info navbar--dropdown-menu"
          >
            <aside></aside>
            <p className="nav-item-desc">
              <FormattedMessage id="nav.label.thirty.index" />
            </p>
          </div>
          {!directorId && (
            <div
              style={
                location?.pathname?.indexOf("/dashboard/businessplan") != -1
                  ? { background: activeBackground }
                  : {}
              }
              onClick={() => onHistory("/dashboard/businessplan")}
              className="nav-info pointer"
            >
              <i className="s14 invisible ">
                <FontAwesomeIcon icon={faMedal} />
              </i>
              <div className="nav-desc museo-sans-500 ">
                <p>
                  <FormattedMessage
                    id="nav.label.six.index"
                    defaultMessage="My Carreer"
                  />
                </p>
              </div>
            </div>
          )}
          <div
            data-bs-toggle="modal"
            href="#ethicsCode"
            role="button"
            className="nav-info navbar--dropdown-menu"
          >
            <aside></aside>
            <p className=" nav-item-desc">
              <FormattedMessage id="nav.label.code.index" />
            </p>
          </div>
        </>
      ) : null}

      {!directorId && (
        <div
          style={
            location?.pathname?.indexOf("/dashboard/calendar") != -1
              ? { background: activeBackground }
              : {}
          }
          onClick={() => onHistory("/dashboard/calendar")}
          className="nav-info"
        >
          <i className="s14">
            <FontAwesomeIcon icon={faCalendarDays} />
          </i>
          <div className="nav-desc museo-sans-500 ">
            <FormattedMessage
              id="nav.label.seven.index"
              defaultMessage="Calendary"
            />
          </div>
        </div>
      )}
    </ul>
  );
}
