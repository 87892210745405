export default function ProgressBarParams({
  completed,
  incompleted,
  header,
  gap,
  darkGray,
  level,
  height,
  status,
  backgroundColor,
}) {
  const containerStyles = {
    height: height ?? 8,
    width: "100%",
    backgroundColor: backgroundColor ? backgroundColor : darkGray ? "#EDEDED" : "#F2F2F2",
    borderRadius: 50,
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  const onClassNameChange = () => {
    if (gap == true || gap == "gap" || status == "gap") {
      return "darkGrayBg";
    }
    if (header) {
      return "blueBg";
    }
    if (!incompleted || status == "completed") {
      return "progressGreeBg";
    }
    if (status == "warning" || status == "normal") {
      return "goldBg";
    }
    if (status == "risk") {
      return "lightRedBg";
    }
    return "goldBg";
  };

  const checkCompletedValue = (completed) => {
    if (!completed || completed < 0 ) {
      return 0;
    }
    return completed;
  };

  return (
    <div className="progress-bar" style={containerStyles}>
      <div
        className={`${onClassNameChange()}`}
        style={{
          height: "100%",
          borderRadius: "inherit",
          width: `${checkCompletedValue(completed)}%`,
        }}
      >
        <span style={labelStyles}></span>
      </div>
    </div>
  );
}
