import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { fetchTravelData } from "./TravelsSideEffect";

const initialState = {
  travels: {
    data: null,
    isLoading: false,
    isError: false,
  }
};

const travelReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(fetchTravelData.pending, (state, action) => {
      state.travels.isLoading = true;
      state.travels.isError = false;
    })
    .addCase(fetchTravelData.fulfilled, (state, action) => {
        state.travels.data = action.payload;
        state.travels.isLoading = false;
        state.travels.isError = false;
        })
    .addCase(fetchTravelData.rejected, (state, action) => {
      state.travels.isLoading = false;
      state.travels.isError = true;
    });
});

export default travelReducer;
