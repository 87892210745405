import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import ShareModalStructureCloseIcon from "../../../../common/Share/ShareModalStructureCloseIcon";

export default function TravelsModalInfo(params) {
  return (
    <div className=" position-relative w-100">
      <div style={{ right: "40px", top: "16px", zIndex: "5" }} className=" pointer position-absolute">
        <ShareModalStructureCloseIcon />
      </div>
      <div
        style={{ padding: "44px" }}
        className=" museo-sans-500 s14 position-relative "
      >
        <div className="d-flex gap-2">
          <HelpIconNoTarget />
          <p className=" museo-sans-700 s24">Períodos cumpliendo tu Meta</p>
        </div>
        <div
          style={{ marginTop: "12px", gap: "14px" }}
          className="d-flex flex-column "
        >
          <p>
            Se toma como base de metas la cantidad de grupos de primera
            generación que hayas tenido históricamente desde el 2011 hasta el
            día de hoy.
          </p>
          <p>
            Para Líderes que hayan viajado a Suiza previo al 2011, se toma como
            base el período 13 de 2010. Por ejemplo, si en ese momento tenían de
            0 a 4, viajan con 5. Si tenían de 5 a 7, viajan con 8. Si tenían de
            9 a 10, viajan con 11, y así sucesivamente.
          </p>
        </div>
        <div className=" d-flex justify-content-center align-items-center ">
          <div
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{ marginTop: "44px" }}
            className=" d-flex justify-content-center align-items-center text-white btnLoginWidth skyBg museo-sans-600 rounded-3 pointer "
          >
            <FormattedMessage id="buttons.understood" />
          </div>
        </div>
      </div>
    </div>
  );
}
