import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SVGInfo } from "../icons/Info.svg";
import { ReactComponent as SVGTriangle } from "../icons/Warning.svg";
import { ModalClipboardContext } from "../../../common/routes/routes";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import EcommerceRegisterModalName from "./modals/EcommerceRegisterModalName";
import EcommerceRegisterModalInfo from "./modals/EcommerceRegisterModalInfo";
import ShareModalWarningMessage from "../../../common/Share/ShareModalWarningMessage";
import { ReactComponent as SVGGreen } from "../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGError } from "../../../EDBs/Presentation/img/redcross.svg";
import {
  activeMP,
  createEcommerce,
  fetchVirtualEcommerce,
  activeTokenMP
} from "../../Framework/redux/EcommerceSideEffect";
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";

export default function EcommerceRegisterForm({
  current,
  howMany,
  onProgressingOnPath,
  isModified,
}) {
  const ecommerceData = useSelector((state) => state.ecommerceData);
  const [inputValue, setInputValue] = useState({ value: "", error: false });
  const [pathOneStatus, setPathOneStatus] = useState("incompleted");
  const [copyToggler, setCopyToggler] = useState(false);
  const [idMP, setIdMP] = useState(null);
  const { setModalTexts } = useContext(ModalClipboardContext);
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();

  const onCopyClick = () => {
    const doThis = async () => {
      const urlTemplate =
        multiLangJSONParse()["ecommerce.register.completed.link"];
      const finalUrl = urlTemplate.replace(
        "{store}",
        inputValue?.value
      );
      try {
        await navigator.clipboard.writeText(finalUrl);
      } catch (error) {
        unsecuredCopyToClipboard(finalUrl);
      }
    };
    const unsecuredCopyToClipboard = async (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };
    doThis();
    setCopyToggler(true);
  };

  const activatePopUp = (modalData) => {
    setModalTexts(modalData);
  };

  const onPathChange = async () => {
    const data = {
      body: {
        id: ecommerceData.ecommerceData.data?.id ?? null,
        clientId: localStorage.getItem("clientId"),
        storeName: inputValue.value,
        phrase:
          ecommerceData.ecommerceData.data?.phrase?.length > 1
            ? ecommerceData.ecommerceData.data?.phrase
            : ecommerceData?.ecommerceDataTopics?.data?.topics[0]?.title,
        history: ecommerceData.ecommerceData.data?.history ?? "",
        socialNetwork: ecommerceData?.ecommerceData?.data?.socialNetwork
          ? { ...ecommerceData?.ecommerceData?.data?.socialNetwork }
          : {
              facebook: null,
              instagram: null,
              youtube: null,
              linkdein: null,
              twitter: null,
              flickr: null,
            },
        colorBackground:
          ecommerceData.ecommerceData.data?.colorBackground !== 0
            ? ecommerceData.ecommerceData.data?.colorBackground
            : 1,
        visible: ecommerceData.ecommerceData.data?.visible ?? true,
      },
      isUpdate: isModified
        ? true
        : ecommerceData?.ecommerceData?.data?.id
        ? true
        : false,
    };
    
    const response = await dispatch(createEcommerce(data));

    if (response?.payload?.status == 400) {
      return;
    }

    if (!location?.pathname?.includes("/new")) {
      await dispatch(fetchVirtualEcommerce());
      history("/dashboard/ecommerce/edit");
      setTimeout(() => {
        const modalData = {
          title: <FormattedMessage id="ecommerce.pop.up.two.title" />,
          text: <FormattedMessage id="ecommerce.pop.up.two.p" />,
          status: "confirm",
          show: false,
          bottom: 84,
        };
        activatePopUp(modalData);
      }, 800);
      return;
    }

    const modalData = {
      title: <FormattedMessage id="ecommerce.pop.up.two.title" />,
      text: <FormattedMessage id="ecommerce.pop.up.two.p" />,
      status: "confirm",
      show: false,
      bottom: 84,
    };
    activatePopUp(modalData);

    setPathOneStatus("completed");
  };

  const validarNombreSitio = (nombreSitio) => {
    const country = localStorage.getItem("country");
    const palabrasProhibidaPorPais = {
      ar: [
        "just",
        "JUST",
        "oficial",
        "OFICIAL",
        "Just",
        "Oficial",
        "SWISS",
        "COLOMBIA",
        "colombia",
        "Colombia",
        "BIENESTAR",
        "YOGA",
        "ACEITES",
        "CONSULTORA",
        "NATURALEZA",
        "AROMATERAPIA",
      ],
      mx: ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial", "SWISS"],
      cl: ["just", "JUST", "oficial", "OFICIAL", "Just", "Oficial", "SWISS"],
      co: [
        "just",
        "JUST",
        "oficial",
        "OFICIAL",
        "Just",
        "Oficial",
        "SWISS",
        "COLOMBIA",
        "colombia",
        "Colombia",
      ],
      cr: [
        "just",
        "JUST",
        "oficial",
        "OFICIAL",
        "Just",
        "Oficial",
        "SWISS",
        "COSTARICA",
        "costarica",
        "Costarica",
        "CostaRica",
      ],
      pa: [
        "just",
        "JUST",
        "oficial",
        "OFICIAL",
        "Just",
        "Oficial",
        "SWISS",
        "COSTARICA",
        "costarica",
        "Costarica",
        "CostaRica",
      ],
      pe: [
        "just",
        "JUST",
        "oficial",
        "OFICIAL",
        "Just",
        "Oficial",
        "SWISS",
        "PERU",
        "peru",
        "Peru",
      ],
      uy: [
        "just",
        "JUST",
        "oficial",
        "OFICIAL",
        "Just",
        "Oficial",
        "SWISS",
        "URUGUAY",
        "uruguay",
        "Uruguay",
      ],
    };
    const palabrasProhibidas = palabrasProhibidaPorPais[country] || [
      "just",
      "JUST",
      "oficial",
      "OFICIAL",
      "Just",
      "Oficial",
      "SWISS",
    ];
    
    const sinAcentos = nombreSitio
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (/\s/.test(sinAcentos)) {
      return { valid: false, message: "No se permiten espacios en blanco." };
    }
    for (const palabra of palabrasProhibidas) {
      if (sinAcentos.includes(palabra)) {
        return {
          valid: false,
          message: `No puede contener la palabra prohibida: ${palabra}`,
        };
      }
    }
    return {
      valid: true,
      message: "El nombre del sitio es válido.",
      value: sinAcentos,
    };
  };

  const validateInputValue = (value) => {
    const errorConditions = [
      {
        condition: value?.length < 1,
        message: "Este campo es obligatorio",
      },
      {
        condition: value?.length <= 3,
        message: "Tu nombre debe tener un mínimo de 3 caracteres.",
      },
      {
        condition: value?.length > 25,
        message: "Tu nombre debe tener un máximo de 25 caracteres",
      },
      {
        message: "Puedes utilizar únicamente letras y números.",
        condition: !/^[A-Za-z0-9]*$/.test(value),
      }
    ];
    for (const { condition, message } of errorConditions) {
      if (condition) {
        setInputValue({
          value: value,
          error: true,
          errorMessage: message,
        });
        return;
      }
    }
    const validationResult = validarNombreSitio(value);
    setInputValue({
      value: validationResult.value,
      error: !validationResult.valid,
      errorMessage: validationResult?.message,
    });
    if (validationResult.valid) {
      const clickMe = document.getElementById("register-modal-continue-pointer");
      clickMe.click();
    }
  };

  const onNewPath = () => {
    onProgressingOnPath();
    setPathOneStatus("incompleted");
  };

  const onChangeInputValue = (e) => {
    setInputValue({ value: e.target.value, error: false });
  };

  
  const onSubmitPathOne = () => {
    validateInputValue(inputValue.value);
  };

  const onSubmitPathTwo = async () => {
    const response = await dispatch(activeMP());
    window.location.href = response.payload.url;
  };

  const tituloId = {
    1: <FormattedMessage id="ecommerce.register.path.select.name" />,
    2: <FormattedMessage id="ecommerce.register.path.select.mp" />,
  };

  const JSXFormIncompleted = (
    <div className=" d-flex flex-column gap-1 w-100">
      <div className="d-flex gap-2">
        <div className=" d-flex align-items-center grayColor s16 museo-sans-400 ">
          <FormattedMessage
            id="ecommerce.register.completed.link"
            values={{ store: "" }}
          />
        </div>
        <input
          value={inputValue.value}
          onChange={(e) => onChangeInputValue(e)}
          placeholder="Ej: tunombre"
          style={{ height: "32px" }}
          className={`w-100 museo-sans-400 s14 ${
            inputValue.error
              ? " input-error-border lightRedColor "
              : "input-border"
          } rounded-3 px-3 `}
        />
      </div>
      {inputValue.error ? (
        <div className=" position-relative lightRedColor museo-sans-400 d-flex s12 gap-1">
          <div>
            <SVGError />
          </div>
          <p style={{ top: "2px" }} className=" position-relative">
            {inputValue?.errorMessage}
          </p>
        </div>
      ) : (
        <div className="d-flex gap-1 s12 museo-sans-400">
          <div>
            <SVGInfo />
          </div>
          <div>
            <FormattedMessage id="ecommerce.register.incompleted.text.info" />
            <span
              data-bs-toggle="modal"
              data-bs-target="#register-modal-name"
              className=" pointer skyColor museo-sans-600"
            >
              {" "}
              <FormattedMessage id="ecommerce.register.incompleted.text.info.span" />{" "}
            </span>
          </div>
        </div>
      )}
    </div>
  );

  const JSXFormCompleted = (
    <EcommerceRegisterCompleted
      inputValue={inputValue}
      onCopyClick={onCopyClick}
      copyToggler={copyToggler}
    />
  );

  const formState = {
    completed: JSXFormCompleted,
    incompleted: JSXFormIncompleted,
  };

  const helpMessageId = {
    1: <FormattedMessage id="ecommerce.register.help.message.id.one" />,
    2: <FormattedMessage id="ecommerce.register.help.message.id.two" />,
  };

  const buttonsPaths = {
    1:
      pathOneStatus == "completed" ? (
        <button
          onClick={onNewPath}
          className=" border-0 skyBg text-white rounded-3 btnLoginWidth s14 museo-sans-600 "
        >
          {howMany == 1 ? (
            <FormattedMessage id="ecommerce.form.link.payment.custom" />
          ) : (
            <FormattedMessage id="ecommerce.register.form.button.path.two" />
          )}
        </button>
      ) : (
        <button
          onClick={onSubmitPathOne}
          className=" border-0 skyBg text-white rounded-3 btnLoginWidth s14 museo-sans-600 "
        >
          <FormattedMessage id="save" />
        </button>
      ),
    2:
      pathOneStatus != "completed" ? (
        <button
          onClick={onSubmitPathTwo}
          className=" border-0 skyBg text-white rounded-3 btnLoginWidth s14 museo-sans-600 "
        >
          <FormattedMessage id="ecommerce.form.link.payment" />
        </button>
      ) : (
        <button
          onClick={onNewPath}
          className=" border-0 skyBg text-white rounded-3 btnLoginWidth s14 museo-sans-600 "
        >
          <FormattedMessage id="ecommerce.form.link.payment.custom" />
        </button>
      ),
  };

  useEffect(() => {
    setTimeout(() => {
      copyToggler && setCopyToggler(false);
    }, 3000);
  }, [copyToggler]);

  useEffect(() => {
    const tokenMP = async () => {
      const params = new URLSearchParams(location.search);
      if (params.size > 0) {
        const code = params.get("code");
        try {
          const response = await dispatch(activeTokenMP(code));
          setIdMP(response.payload.accountMP);
          setPathOneStatus("completed");
          const modalData = {
            title: <FormattedMessage id="ecommerce.pop.up.one.title" />,
            text: <FormattedMessage id="ecommerce.pop.up.one.p" />,
            status: "info",
            show: false,
            bottom: 84,
          };
          activatePopUp(modalData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      
    };
  
    tokenMP();
  }, [location]);

  return (
    <div
      style={{ gap: "20px", width: "600px" }}
      className="d-flex flex-column "
    >
      <ShareModalStructure maxWidth={"496px"} id={"register-modal-info"}>
        <EcommerceRegisterModalInfo />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"496px"} id={"register-modal-continue"}>
        <ShareModalWarningMessage
          buttonConfirmedMessage={<FormattedMessage id="buttons.confirm" />}
          description={
            <FormattedMessage
              id="ecommerce.register.moda.warning.one.p.one"
              values={{ store: inputValue?.value }}
            />
          }
          title={<FormattedMessage id="ecommerce.register.title.name" />}
          onButtonConfirmedClick={onPathChange}
          buttonIsCanceled={true}
        />
      </ShareModalStructure>
      <div className="position-relative d-flex gap-2 museo-sans-400 s20  ">
        <p>{tituloId[current]}</p>
        {pathOneStatus == "completed" && (
          <div
            onClick={onCopyClick}
            style={{ width: "16px", height: "16px", top: "-2px" }}
            className=" position-relative svg-force-size"
          >
            <SVGGreen />
          </div>
        )}
      </div>
      {current == 1 ? (
        <EcommerceRegisterFormPathOne formState={formState[pathOneStatus]} />
      ) : (
        <EcommerceRegisterFormPathTwo mp={idMP} pathOneStatus={pathOneStatus} />
      )}
      {pathOneStatus != "completed" && (
        <div
          style={{ width: "600px", height: "70px" }}
          className="position-relative bgWarningHelpMessage d-flex gap-3 p-3 museo-sans-400 s14"
        >
          <div className=" svg-force-color blackColor">
            <SVGTriangle />
          </div>
          <p>{helpMessageId[current]}</p>
        </div>
      )}
      <div style={{ marginTop: "20px" }}>{buttonsPaths[current]}</div>
      <div
        data-bs-toggle="modal"
        data-bs-target="#register-modal-continue"
        id="register-modal-continue-pointer"
      ></div>
    </div>
  );
}

export function EcommerceRegisterFormPathTwo(params) {
  return (
    <div style={{ gap: "20px" }} className="d-flex flex-column">
      <div className="d-flex gap-1">
        <p className=" museo-sans-600 s14">
          <FormattedMessage id="ecommerce.register.form.path.two.title" />
        </p>
        {
          params?.mp &&
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M7.06732 9.20016L5.63398 7.76683C5.51176 7.64461 5.35621 7.5835 5.16732 7.5835C4.97843 7.5835 4.82287 7.64461 4.70065 7.76683C4.57843 7.88905 4.51732 8.04461 4.51732 8.2335C4.51732 8.42238 4.57843 8.57794 4.70065 8.70016L6.60065 10.6002C6.73398 10.7335 6.88954 10.8002 7.06732 10.8002C7.2451 10.8002 7.40065 10.7335 7.53398 10.6002L11.3007 6.8335C11.4229 6.71127 11.484 6.55572 11.484 6.36683C11.484 6.17794 11.4229 6.02238 11.3007 5.90016C11.1784 5.77794 11.0229 5.71683 10.834 5.71683C10.6451 5.71683 10.4895 5.77794 10.3673 5.90016L7.06732 9.20016ZM8.00065 14.6668C7.07843 14.6668 6.21176 14.4918 5.40065 14.1418C4.58954 13.7918 3.88398 13.3168 3.28398 12.7168C2.68398 12.1168 2.20898 11.4113 1.85898 10.6002C1.50898 9.78905 1.33398 8.92238 1.33398 8.00016C1.33398 7.07794 1.50898 6.21127 1.85898 5.40016C2.20898 4.58905 2.68398 3.8835 3.28398 3.2835C3.88398 2.6835 4.58954 2.2085 5.40065 1.8585C6.21176 1.5085 7.07843 1.3335 8.00065 1.3335C8.92287 1.3335 9.78954 1.5085 10.6007 1.8585C11.4118 2.2085 12.1173 2.6835 12.7173 3.2835C13.3173 3.8835 13.7923 4.58905 14.1423 5.40016C14.4923 6.21127 14.6673 7.07794 14.6673 8.00016C14.6673 8.92238 14.4923 9.78905 14.1423 10.6002C13.7923 11.4113 13.3173 12.1168 12.7173 12.7168C12.1173 13.3168 11.4118 13.7918 10.6007 14.1418C9.78954 14.4918 8.92287 14.6668 8.00065 14.6668Z"
            fill="#16BE7D"
          />
        </svg>
        }
      </div>
      <p className=" grayColor museo-sans-400 s14">
        {params?.pathOneStatus == "completed" ? (
          `ID ${params?.mp}`
        ) : (
          <FormattedMessage id="ecommerce.register.form.path.two.p" />
        )}
      </p>
    </div>
  );
}

export function EcommerceRegisterFormPathOne({ formState }) {
  return (
    <div className="w-100">
      <div className="d-flex museo-sans-600 s14 gap-1 mb-2">
        <p>
          <FormattedMessage id="ecommerce.register.title.name" />
        </p>
        <div
          data-bs-toggle="modal"
          data-bs-target="#register-modal-info"
          style={{ width: "16px", height: "16px", top: "-2px" }}
          className=" position-relative svg-force-size pointer"
        >
          <HelpIconNoTarget />
        </div>
      </div>
      {formState}
    </div>
  );
}

export function EcommerceRegisterCompleted(params) {
  const [copyToggler, setCopyToggler] = useState(false);

  const onCopyClick = () => {
    const doThis = async () => {
      const urlTemplate =
        multiLangJSONParse()["ecommerce.register.completed.link"];
      const finalUrl = urlTemplate.replace("{store}", params.inputValue.value);
      try {
        await navigator.clipboard.writeText(finalUrl);
      } catch (error) {
        unsecuredCopyToClipboard(finalUrl);
      }
    };
    const unsecuredCopyToClipboard = async (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };
    doThis();
    setCopyToggler(true);
  };

  useEffect(() => {
    setTimeout(() => {
      copyToggler && setCopyToggler(false);
    }, 3000);
  }, [copyToggler]);

  return (
    <div className=" position-relative d-flex gap-2">
      <p
        className={`s20 museo-sans-400 ${
          params?.onStatusConfig ? "lightRedColor" : "grayColor"
        } `}
      >
        <FormattedMessage
          id="ecommerce.register.completed.link"
          values={{ store: params.inputValue.value }}
        />
      </p>

      <div
        onClick={onCopyClick}
        style={{ top: "7px" }}
        className=" position-relative pointer skyColor s14 museo-sans-600"
      >
        {copyToggler && (
          <div
            style={{
              height: "38px",
              width: "120px",
              top: "-8px",
            }}
            className=" justify-content-center greenModalBg s16 d-flex align-items-center gap-2 position-absolute"
          >
            <i
              style={{ justifySelf: "center" }}
              className=" greenColor bi bi-check-circle"
            ></i>
            <p className="greenColor">
              <FormattedMessage id="buttons.copied" />
            </p>
          </div>
        )}
        <FormattedMessage id="copy" />
      </div>
    </div>
  );
}
