import { useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { useDispatch, useSelector } from "react-redux";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";
import ShareChevronRight from "../../../common/Share/ShareChevronRight";
import ShareModalIndex from "../../../common/Share/ShareModalIndex";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import { fetchTravelData } from "../../Framework/redux/TravelsSideEffect";
import TravelsModalInfo from "./modals/TravelsModalInfo";
import TravelsModalList from "./modals/TravelsModalList";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import { ReactComponent as SVGGrayCheck } from "../../../OriginRoad/SVGs/GapCheck.svg";
import { ReactComponent as SVGGreen } from "../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGFailed } from "../../../FirstSteps/Presentation/SVGs/NoAlcanzado/ic-x-circle-sm.svg";
import { ReactComponent as SVGIncomplete } from "../../../FirstSteps/Presentation/SVGs/Pending/ic-item-pending-sm-filled-yellow.svg";

import { ReactComponent as SVGCheckGreen } from "../../../Dashboard/Presentation/SVGs/checkGreen.svg";
import { ReactComponent as SVGCheckBlue } from "../../../Dashboard/Presentation/SVGs/checkBlue.svg";
import { ReactComponent as SVGCrossRed } from "../../../Dashboard/Presentation/SVGs/crossRed.svg";
import { ReactComponent as SVGGap } from "../../../OriginRoad/SVGs/warning.svg";

export default function DashboardTravels() {
  const dispatch = useDispatch();
  const travelData = useSelector((state) => state.travelData);
  const parentRef = useRef(null);
  const biggerThan1400 = useMediaPredicate("(min-width: 1441px)");
  const [height, setHeight] = useState("auto");

  const JSXElementController = (type, params) => {
    const JSXElement = {
      CARD: <DashboardTravelHelpMessage {...params} />,
      METRIC_PROGRESS_BAR: <DashboardTravelProgressContainer {...params} />,
      METRIC_RATIO: <DashboardTravelRowInfo travelHere={"travels/qualified"} {...params} />,
      TEXT: <p className="s12 museo-sans-500 grayColor ">{params[0]?.value}</p>,
      TITLE: <DashboardTravelTitle {...params} />,
    };

    return JSXElement[type];
  };

  const onStartUp = async () => {
    await dispatch(fetchTravelData());
  };

  useEffect(() => {
    onStartUp();
    if (parentRef.current) {
      setHeight(`${parentRef.current.clientHeight}px`);
    }
  }, [parentRef?.current?.clientHeight]);

  return (
    <>
      {travelData?.travels?.data?.travels?.length > 0 && (
        <div className={` ${biggerThan1400 ? "mb-5" : ""} w-100 museo-sans-500 `}>
          <ShareModalStructure maxWidth={"496px"} id={"modal-travels-info"}>
            <TravelsModalInfo />
          </ShareModalStructure>
          <ShareModalStructure maxWidth={"544px"} id={"modal-travels-list"}>
            <TravelsModalList />
          </ShareModalStructure>
          <div className="d-flex gap-2 mb-4">
            <p className="s20">Viajes Just 2026</p>
            <div style={{ width: "16px" }} className=" pointer svg-force-size">
              <HelpIconNoTarget />
            </div>
            {/* <ShareModalIndex id={"modal-travels-list"}>
          <div style={{ width: "16px" }} className=" pointer svg-force-size">
            <HelpIconNoTarget />
        
          </div>
        </ShareModalIndex> */}
          </div>
          <div ref={parentRef} className="d-flex align-items-stretch gap-4 h-100">
            {travelData?.travels?.data?.travels?.length > 0 &&
              travelData?.travels?.data?.travels?.map((travel, index) => {
                return (
                  <DashboardTravelsContainer
                    onlyOne={travelData?.travels?.data?.travels?.length == 1}
                    title={travel?.header?.title}
                    link={travel?.header?.termsLink}
                    height={height}
                  >
                    <>
                      <div className="w-100 d-flex flex-column h-100 justify-content-between gap-3">
                        {travel?.body?.map((element, index) => {
                          return JSXElementController(
                            element?.type,
                            element?.properties
                          );
                        })}
                      </div>

                      {travel?.footer?.length > 0 && (
                        <DashboardTravelRowInfoFooter
                          travel={travel?.footer}
                          properties={travel?.footer[0]?.properties}
                        />
                      )}
                    </>
                  </DashboardTravelsContainer>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

export function DashboardTravelProgressContainer(params) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const [values, setValues] = useState({});

  useEffect(() => {
    let aux = {};
    Object.values(params)?.map((element, index) => {
      aux = { ...aux, [element?.type]: element?.value };
    });
    setValues(aux);
  }, [params]);

  return (
    <div className=" d-flex flex-column gap-2 w-100">
      <div className="d-flex justify-content-between w-100 s14">
        <p>{values?.label}</p>
        <div>
          <p className="d-flex gap-1 dm-sans-medium">
            {formatAmount(
              values?.currentAmount,
              cultureInfo?.languageCode,
              cultureInfo?.defaultCurrencyCode
            )}
            <span className="grayColor ">
              /{" "}
              {formatAmount(
                values?.totalAmount,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </span>
          </p>
        </div>
      </div>
      <div>
        <ProgressBarParams
          completed={(values.currentAmount * 100) / values.totalAmount}
          incompleted={values?.status != "completed"}
          status={values?.status}
          backgroundColor={"#F0F0F0"}
        />
      </div>
    </div>
  );
}

export function DashboardTravelRowInfoFooter(params) {
  const [values, setValues] = useState({});

  useEffect(() => {
    let aux = {};
    Object.values(params?.properties)?.map((element, index) => {
      aux = { ...aux, [element?.type]: element?.value };
    });
    setValues(aux);
  }, [params]);
//id={"modal-travels-list"}
      // <ShareModalIndex  >
      //   </ShareModalIndex>
  return (
    <>
      <hr className="m-0 my-3" />
      <div className="donutHeaderBg  rounded-3 p-3 ">
        <DashboardTravelRowInfo
          title={values?.label ?? "Grupos 1G calificando"}
          actual={values?.currentValue}
          total={values?.totalValue}
          chevron={false}
        />
      </div>
    </>
  );
}

export function DashboardTravelRowInfo(params) {
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  const onVoid = () => {};

  const adjustDirection = (travelHere) => {
    console.log("A: ", travelHere)
    console.log(values?.label)
    return `${travelHere}/${values?.label}`
  };

  useEffect(() => {
    if (params?.total) {
      return;
    }
    let aux = {};
    Object.values(params)?.map((element, index) => {
      aux = { ...aux, [element?.type]: element?.value };
    });
    setValues(aux);
  }, [params]);

  // onClick={() => navigate("travels/qualified")}

  return (
    <div className="d-flex s14 museo-sans-500 justify-content-between align-items-center w-100">
      <p>{params?.title ?? values?.label}</p>
      <div className="d-flex gap-1 dm-sans-medium align-items-center pointer ">
        <p
          style={{ minWidth: "50px" }}
          className="d-flex justify-content-end gap-1"
        >
          {params?.actual ?? values?.currentValue}
          <span className=" grayColor">
            / {params?.total ?? values?.totalValue}
          </span>
        </p>
        {
          params?.chevron &&
        <ShareChevronRight />
        }
      </div>
    </div>
  );
}

export function DashboardTravelHelpMessage(params) {
  const [values, setValues] = useState({});

  const onLabelBaldText = (label) => {
    if (!label?.includes("<b>")) {
      return label;
    }
    let valueString = label?.split("<b>");
    let valueString2 = valueString[1]?.split("</b>");
    return (
      <>
        {valueString[0]}
        <span className=" museo-sans-700 ">{valueString2[0]}</span>
        {valueString2[1]}
      </>
    );
  };
  const objectBGStyles = {
    completed: {
      backgroundColor: "#e7f5f0",
      color: "#199666",
    },
    risk: {
      color: "#62221F",
      background: "#FEF0F0",
    },
    gap: {
      color: "#4D4D4D",
      background: "#FEFBEC",
    },
    warning: {
      color: "#4D4D4D",
      background: "#FEFBEC",
    },
    normal: {
      color: "#171A22",
      background: "#ECF0FA",
    },
    info: {
      color: "#456ECE",
      background: "#ECF0FA",
    },
  };
  const changeStyleByType = {
    blue: "info",
    red: "risk",
    yellow: "warning",
  };
  const SVGParams = {
    completed: {
      width: "16px",
      height: "16px",
      top: "-4px"
    },
    info: {
      width: "16px",
      height: "16px",
      top: "-4px"
    },
    risk: {
      width: "16px",
      height: "16px",
      top: "-4px"
    },
    gap: {
      width: "16px",
      height: "16px",
      top: "-4px"
    },
    warning: {
      width: "16px",
      height: "16px",
      top: "-4px"
    },
  };
  const objectSVGs = {
    completed: <SVGCheckGreen />,
    risk: <SVGCrossRed />,
    gap: <SVGGap />,
    warning: <SVGGap />,
    info: <SVGCheckBlue />,
  };
  useEffect(() => {
    let aux = {};
    Object.values(params)?.map((element, index) => {
      aux = { ...aux, [element?.type]: element?.value };
    });
    setValues(aux);
  }, [params]);

  return (
    <div
      style={objectBGStyles[changeStyleByType[values?.theme]]}
      className={` d-flex gap-3 p-3 rounded-3 align-items-center  `}
    >
      {
        objectSVGs[changeStyleByType[values?.theme]] &&
        <div style={SVGParams[changeStyleByType[values?.theme]]} className=" position-relative svg-force-size" >
          {
            objectSVGs[changeStyleByType[values?.theme]]
          }
          </div>
      }
      <p className="s14">{onLabelBaldText(values?.label)}</p>
    </div>
  );
}

export function DashboardTravelsContainer(params) {

  const id = `link-${params?.title?.replaceAll(" ", "-")}`

  const onClickA = () => {
    const clickMe = document.getElementById(id);
    clickMe.click();
  };

  return (
    <div className=" cards-shadow d-flex flex-column w-100 p-4 bg-white rounded-4 ">
      <div className="d-flex align-items-center gap-2 mb-3">
        <p className=" s20 me-1">{params?.title}</p>
        <p onClick={onClickA} className=" s14 museo-sans-700 skyColor pointer ">
          <FormattedMessage id="origin.road.link" />{" "}
        </p>
        <a target="_blank" id={id} href={`${params?.link}`} >
          
        </a>
      </div>
      <div
        className={`${
          params?.onlyOne
            ? "d-flex w-100 h-100 flex-column justify-content-between "
            : "d-flex w-100 h-100 flex-column justify-content-between "
        } `}
      >
        {params?.children}
      </div>
    </div>
  );
}

export function DashboardTravelTitle(params) {
  const [values, setValues] = useState({});

  const onControlStatus = (status) => {
    const arrayStatusSVGs = {
      completed: <SVGGreen />,
      warning: <SVGIncomplete />,
      risk: <SVGFailed />,
      gap: <SVGGrayCheck />,
    };

    return arrayStatusSVGs[status];
  };

  const onControlText = (label) => {
    if (!label) {
      return ""
    };
    let aux = label?.split(":");
    return (
      <p className=" museo-sans-700">
        {aux[0]}: <span className=" museo-sans-500 ">{aux[1]}</span>
      </p>
    );
  };

  useEffect(() => {
    let aux = {};
    Object.values(params)?.map((element, index) => {
      aux = { ...aux, [element?.type]: element?.value };
    });
    setValues(aux);
  }, [params]);

  return (
    <div>
      <div className="d-flex align-items-end gap-1" >
        {onControlText(values?.label)}
        <div style={{top: "-2px"}} className=" position-relative" >
          {onControlStatus(values?.trailing)}
        </div>
      </div>
      <hr className="m-0 mt-3" />
    </div>
  );
}

