import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import ShareModalIndex from "../../../../common/Share/ShareModalIndex";
import ShareModalStructureCloseIcon from "../../../../common/Share/ShareModalStructureCloseIcon";

export default function TravelsModalList(params) {
  return (
    <div className=" position-relative w-100 s14 museo-sans-500">
      <div
        style={{ padding: "32px", paddingBottom: "24px" }}
        className=" position-relative w-100 "
      >
        <div className="d-flex justify-content-between">
          <p className=" s20 museo-sans-500">Períodos cumpliendo tu Meta</p>
          <div className=" position-relative">
            <ShareModalStructureCloseIcon right={"0px"} />
          </div>
        </div>
        <div className="d-flex align-items-center dm-sans-medium s16 gap-1">
          <p>3</p>
          <span className="grayColor">/ 10</span>
          <ShareModalIndex id={"modal-travels-info"}  >
            <div style={{ width: "16px", top: "-1px" }} className=" position-relative svg-force-size">
              <HelpIconNoTarget />
            </div>
          </ShareModalIndex>
        </div>
        <div style={{ marginTop: "12px" }} className="">
          Si en este período no alcanzas{" "}
          <span className=" museo-sans-700">
            5 grupos calificados de 1ra Generación
          </span>
          , pierdes la posibilidad de viajar este año ya que no alcanzarías las
          calificaciones en 8 de los 13 períodos del 2025
        </div>
      </div>
      <hr className="m-0" />
      <div
        style={{ padding: "32px", paddingTop: "24px" }}
        className=" position-relative w-100 "
      ></div>
    </div>
  );
}
