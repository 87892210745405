import { configureStore } from '@reduxjs/toolkit';
import meetingReducer from './Dashboard/Framework/redux/meetingReducer';
import scoreReducer from './Dashboard/Framework/redux/scoreReducer';
import loginReducer from './Login/Framework/redux/loginReducer';
import earningReducer from './Earning/redux/earningReducer';
import commonReducer from './common/redux/commonReducer';
import headerReducer from './common/Header/Framework/HeaderReducer';
import EDBsReducer from './EDBs/Framework/redux/EDBsReducer';
import goodPracticeReducer from './GoodPractice/Framework/redux/GoodPracticeReducer';
import EDBDetailsReducer from './EDBs/Framework/redux/EDBDetailsReducer';
import calendarReducer from './Calendar/Framework/redux/CalendarReducer';
import periodReducer from './Account/Framework/redux/AccountReducer';
import performanceReducer from './Performance/Framework/redux/performanceReducer'
import businessReducer from './BusinessPlan/Framework/redux/BusinessReducer';
import descendantReducer from './Descendants/Framework/redux/DescendantsReducer';
import onBoardingReducer from './common/redux/onBoardingReducer';
import FirstStepsReducer from './Dashboard/Framework/redux/FirstStepsReducer';
import FirstStepsAllReducer from './FirstSteps/Framework/redux/FirstStepsAllReducer';
import statusAccountReducer from './StatusAccount/Framework/redux/StatusAccountReducer';
import ofertsReducer from './Oferts&Features/Framework/redux/OfertsReducer';
import clientListReducer from './ClientList/Framework/redux/ClientListReducer';
import invitationsReducer from './Invitations/Framework/redux/InvitationsReducer';
import EDBFormReducer from './EDBs/Framework/redux/EDBFormReducer';
import CAOReducer from './OriginRoad/Framework/redux/OriginRoadReducer';
import ecommerceReducer from './Ecommerce/Framework/redux/EcommerceReducer';
import travelReducer from './Travels/Framework/redux/TravelsReducer';

export const store = configureStore({
  reducer: {
    userInfo: loginReducer,
    userData: null,
    earning: earningReducer,
    meetingData: meetingReducer,
    scoreData: scoreReducer,
    common: commonReducer,
    headerData: headerReducer,
    edbData: EDBsReducer,
    edbDetailsData: EDBDetailsReducer,
    goodPracticeData: goodPracticeReducer,
    calendarData: calendarReducer,
    periodBalanceData : periodReducer,
    performanceData: performanceReducer,
    businessData: businessReducer,
    descendantData: descendantReducer,
    onBoardingData: onBoardingReducer,
    firstStepsData: FirstStepsReducer,
    firstStepsAllReducer: FirstStepsAllReducer,
    statusAccountData: statusAccountReducer,
    ofertsData: ofertsReducer,
    clientListData: clientListReducer,
    invitationData: invitationsReducer,
    edbFormData: EDBFormReducer,
    CAOData: CAOReducer,
    ecommerceData: ecommerceReducer,
    travelData: travelReducer,
  },
});
